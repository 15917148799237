import React from 'react';
import Sponser4 from '../../assets/images/abortion.png';
import Sponser1 from '../../assets/images/fetus.png';
import Sponser5 from '../../assets/images/motherhood.png';
import sponserShape from '../../assets/images/sponser-shape.png';
import Sponser3 from '../../assets/images/tubal-ligation (2).png';
import Sponser2 from '../../assets/images/womb.png';

function SponserHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-sponser-area pb-100 ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <center>
                                    <h3 className="">I want to..</h3>
                                </center>
                                {/* <p>Join over 40,000 businesses worldwide.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-sponser-box d-flex justify-content-center">
                                <div className="sponser-item">
                                    <center>
                                        <img src={Sponser1} alt="" />
                                        <br />
                                        <p className="wantto">I want to have a baby</p>
                                    </center>
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser2} alt="" />
                                    <br />
                                    <p className="wantto">I want to Save uterus</p>
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser3} alt="" />
                                    <br />
                                    <p className="wantto">I want to Tubal block</p>
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser4} alt="" />
                                    <br />
                                    <p className="wantto">I want to prevent abortion</p>
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser5} alt="" />
                                    <br />
                                    <p className="wantto">I want to have a painless delivery</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sponser-shape">
                    <img src={sponserShape} alt="" />
                </div>
            </section>
        </>
    );
}

export default SponserHomeTwo;
