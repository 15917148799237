import React from 'react';
import { Link } from 'react-router-dom';
import gif from '../assets/images/phone_calling.gif';

function Navigation({ lang = false }) {
    return (
        <>
            {lang ? (
                <ul>
                    <li>
                        <a href="#">
                            أنا <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/">الصفحة الرئيسية 1</Link>
                            </li>
                            <li>
                                <Link to="/home-two">الصفحة الرئيسية 2</Link>
                            </li>
                            <li>
                                <Link to="/home-three">المنزل 3</Link>
                            </li>
                            <li>
                                <Link to="/home-four">المنزل 4</Link>
                            </li>
                            <li>
                                <Link to="/home-five">المنزل 5</Link>
                            </li>
                            <li>
                                <Link to="/home-six">الصفحة الرئيسية 6</Link>
                            </li>
                            <li>
                                <Link to="/home-seven">المنزل 7</Link>
                            </li>
                            <li>
                                <Link to="/home-eight">الصفحة الرئيسية 8</Link>
                            </li>
                            <li>
                                <Link to="/home-dark">الصفحة الرئيسية الظلام</Link>
                            </li>
                            <li>
                                <Link to="/home-rtl">الصفحة الرئيسية Rtl</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/service">خدمة</Link>
                    </li>
                    <li>
                        <a href="#">
                            الصفحات <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/about-us">عن</Link>
                            </li>
                            <li>
                                <Link to="/error">خطأ</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            أخبار <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/news">صفحة الأخبار </Link>
                            </li>
                            <li>
                                <Link to="/news/single-news">أخبار واحدة</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/contact">اتصل</Link>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                        <Link to="/#">About Us</Link>
                    </li>
                    <li>
                        <a href="#">
                            Services <i className="fal fa-angle-down" />
                        </a>
                        {/* <ul className="sub-menu">
                            <li>
                                <Link to="/about-us">About</Link>
                            </li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/">Doctors</Link>
                    </li>
                    <li>
                        <Link to="/#">Contact Us</Link>
                    </li>
                    <li>
                        <a className="main-btn ml-30" href="#">
                            Book an Appointment
                        </a>
                    </li>
                    <li>
                        <a className="ml-30" href="#" style={{ display: 'flex' }}>
                            <img src={gif} alt="" />
                            <span style={{ margin: 'auto' }}>
                                <h1 style={{ fontSize: '25px' }}>94451 21117</h1>
                            </span>
                        </a>
                    </li>
                </ul>
            )}
        </>
    );
}

export default Navigation;
