import React from 'react';
import ReactFloaterJs from 'react-floaterjs';
import Sponser2 from '../../assets/images/assisted-reproduction.png';
import Sponser1 from '../../assets/images/fetus.png';
import Sponser4 from '../../assets/images/laparoscopy.png';
import Sponser5 from '../../assets/images/motherhood.png';
import border from '../../assets/images/rhea-bg.png';
import shapeTwo from '../../assets/images/rhea2.png';
import shapeThree from '../../assets/images/rhea3.png';
import shapeFour from '../../assets/images/rhea4.png';
import Sponser3 from '../../assets/images/tubal-ligation (2).png';

function ServicesHomeTwo({ className }) {
    return (
        <>
            <section
                className={`appie-services-2-area appie-hero-area pb-100 ${className}`}
                id="service"
                style={{
                    backgroundImage: `url(${border})`,
                    backgroundSize: ' 200px ',
                    backgroundPosition: 'bottom right',
                    backgroundRepeat: ' no-repeat ',
                }}
            >
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3
                                    className="appie-title"
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    I want to..
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon" style={{ textAlign: 'center' }}>
                                    <ReactFloaterJs>
                                        <img src={Sponser1} alt="" style={{ width: '80px' }} />
                                    </ReactFloaterJs>
                                </div>
                                <h4 className="title">I want to have a baby</h4>
                                {/* <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon" style={{ textAlign: 'center' }}>
                                    <ReactFloaterJs>
                                        <img src={Sponser2} alt="" style={{ width: '80px' }} />
                                    </ReactFloaterJs>
                                </div>
                                <h4 className="title">I want to do IVF</h4>
                                {/* <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon" style={{ textAlign: 'center' }}>
                                    <ReactFloaterJs>
                                        <img src={Sponser3} alt="" style={{ width: '80px' }} />
                                    </ReactFloaterJs>
                                </div>
                                <h4 className="title">I want to remove Tubal block</h4>
                                {/* <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon" style={{ textAlign: 'center' }}>
                                    <ReactFloaterJs>
                                        <img src={Sponser5} alt="" style={{ width: '80px' }} />
                                    </ReactFloaterJs>
                                </div>
                                <h4 className="title">I want to have a painless delivery</h4>
                                {/* <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon" style={{ textAlign: 'center' }}>
                                    <ReactFloaterJs>
                                        <img src={Sponser4} alt="" style={{ width: '80px' }} />
                                    </ReactFloaterJs>
                                </div>
                                <h4 className="title">
                                    I want to do family planning (Laparoscopy)
                                </h4>
                                {/* <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" style={{ width: '30px' }} />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" style={{ width: '30px' }} />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" style={{ width: '30px' }} />
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
