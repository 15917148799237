import $ from 'jquery';
import Carousel from 'nuka-carousel/lib/carousel';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import blogOne from '../../assets/images/banner1.jpg';
import Form from './Form';

function Banner() {
    const [smShow, setSmShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setSmShow(true);
        }, 2000);
    }, []);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [message, setMessage] = useState('');
    const [result, setResult] = useState('');
    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
            success(data) {
                setResult(data);
                setName('');
                setEmail('');
                setPhone('');
                setLocation('');
                setMessage('');
            },
        });
    };
    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <Carousel>
                        <img src={blogOne} alt="" />
                    </Carousel>
                </div>
                <div className="col-md-4">
                    <Form />
                </div>
            </div>
            <Modal
                size="lg"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Book an Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className="contact-section" style={{ padding: '10px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact-form">
                                        <form
                                            className="row"
                                            action="https://rhea.bcchealthcarebranding.com/server/mail.php"
                                            method="post"
                                            onSubmit={(event) => handleSumbit(event)}
                                        >
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Name"
                                                    id="name"
                                                    name="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    placeholder="Email"
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    id="phone"
                                                    name="phone"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Location"
                                                    id="phone"
                                                    name="phone"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <textarea
                                                    name="message"
                                                    placeholder="How can we help?"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                ></textarea>
                                            </div>
                                            <button
                                                type="submit"
                                                className="site-btn site-btn__2 mt-10 main-btn"
                                            >
                                                Send
                                            </button>
                                            <h4 className="title">{result}</h4>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Banner;
