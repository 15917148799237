import React from 'react';
import thumb from '../../assets/images/hospital-rhea.png';
import shapeSix from '../../assets/images/rhea4.png';
import shapeSeven from '../../assets/images/shape/shape-7.png';
import shapeEight from '../../assets/images/shape/shape-8.png';

function TrafficHomeOne({ className }) {
    return (
        <section
            className={`appie-traffic-area appie-features-area pt-90 pb-100 ${className}`}
            id="features"
            style={{
                backgroundImage: 'none',
                backgroundSize: ' 200px ',
                backgroundPosition: 'bottom left',
                backgroundRepeat: ' no-repeat ',
                backgroundColor: '#f7eff9',
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6" style={{ alignSelf: 'center' }}>
                        <div className="appie-traffic-title">
                            {/* <span>Traffic</span> */}
                            <h3 className="title">Why Rhea?</h3>
                            <br />
                            <p style={{ fontSize: '18px', lineHeight: '1.5' }}>
                                Rhea IVF & Women's Hospital is one of the best fertility center in
                                Tamil Nadu. Our hospital is a state-of-the-art IVF centre equipped
                                with advanced facilities to ensure that every hopeful parent
                                realises their dream. Inaugurated on 14th April 2019, we are a
                                first-of-its-kind hospital in Kallakurichi, Tamil Nadu. We are
                                providing the highest quality of care and affordable treatments to
                                patients from all sectors of society. We envision bringing quality
                                and the best fertility care within the reach of all and maintaining
                                excellence in the field of reproductive medicine and clinical
                                embryology.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img
                            className="wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                            src={thumb}
                            alt=""
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shapeSix} alt="" style={{ width: '30px' }} />
            </div>
            <div className="features-shape-2">
                <img src={shapeSeven} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shapeEight} alt="" />
            </div>
        </section>
    );
}

export default TrafficHomeOne;
