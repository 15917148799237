import React, { useEffect } from 'react';
import logo7 from '../../assets/images/logo-7.png';
import logo from '../../assets/images/Rhea_01.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../Navigation';

function HomeOneHeader({ darkEnable = false, dark, className, action }) {
    useEffect(() => {
        StickyMenu();
    });
    return (
        <header className={`appie-header-area appie-sticky ${className || ''}`}>
            <div className="">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="appie-logo-box">
                                {darkEnable && dark ? (
                                    <a href="/">
                                        <img src={logo7} alt="" />
                                    </a>
                                ) : (
                                    <a href="/">
                                        <img src={logo} alt="" style={{ width: '180px' }} />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div
                            className="col-lg-10 col-md-1 col-sm-1 order-3 order-sm-2"
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <div className="appie-header-main-menu">
                                <Navigation />
                            </div>
                        </div>
                        <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="appie-btn-box text-right">
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeOneHeader;
