import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';

function PricingHomeOne({ className }) {
    return (
        <>
            <section className={`appie-pricing-area pt-90 pb-90 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">The Stories Of Joy & Hope</h3>
                                {/* <p>Different layouts and styles for team sections.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="tabed-content">
                        <ElfsightWidget widgetID="dac13a20-8107-41f9-80b0-61ac2ce0d413" />
                    </div>
                </div>
            </section>
        </>
    );
}

export default PricingHomeOne;
