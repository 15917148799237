import React from 'react';
import IconFour from '../../assets/images/childcare.png';
import IconThree from '../../assets/images/delivery.png';
import IconOne from '../../assets/images/female.png';
import IconTwo from '../../assets/images/sperm.png';

function ServicesHomeOne({ className }) {
    return (
        <section
            className={`appie-service-area pt-90 pb-100 ${className}`}
            id="service"
            style={{
                backgroundImage: 'none',
                backgroundSize: ' 200px ',
                backgroundPosition: 'bottom left',
                backgroundRepeat: ' no-repeat ',
            }}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Treatment for Women</h3>
                            {/* <p>The full monty spiffing good time no biggie cack grub fantastic. </p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                                {/* <span>1</span> */}
                            </div>
                            <h4 className="appie-title">Correction</h4>
                            <p className="text-center">
                                Correction of Female Reproductive Organs to aid Conception
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                                {/* <span>2</span> */}
                            </div>
                            <h4 className="appie-title">Conception</h4>
                            <p className="text-center">
                                Increase the conception rate through Latest Treatments
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                                {/* <span>3</span> */}
                            </div>
                            <h4 className="appie-title">Delivery</h4>
                            <p className="text-center">
                                Maintain Problematic Pregnancies & Give a healthy baby through
                                painless delivery.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                                {/* <span>4</span> */}
                            </div>
                            <h4 className="appie-title">Child Care</h4>
                            <p className="text-center">
                                Holistic Paediatric Care Covering Perinatal Complications
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
