import React from 'react';
import ReactFloaterJs from 'react-floaterjs';
import point from '../../assets/images/rhea-bg.png';
import border from '../../assets/images/rhea1.png';
import shapeTwo from '../../assets/images/rhea2.png';
import shapeThree from '../../assets/images/rhea3.png';
import shapeFour from '../../assets/images/rhea4.png';
import heroThumbOne from '../../assets/images/uterus.png';

function SaveUterus({ className }) {
    return (
        <>
            <section
                className={`appie-hero-area ${className || ''}`}
                style={{
                    backgroundImage: `url(${border})`,
                    backgroundSize: ' 200px ',
                    backgroundPosition: 'bottom right',
                    backgroundRepeat: ' no-repeat ',
                }}
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <ReactFloaterJs>
                                        <img src={heroThumbOne} alt="" />
                                    </ReactFloaterJs>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                {/* <span>About</span> */}
                                <h1 className="appie-title">Saving Uterus</h1>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="appie-traffic-service mb-30">
                                            <div className="icon">
                                                {/* <i className="fal fa-check" /> */}
                                                <img src={point} alt="" style={{ width: '35px' }} />
                                            </div>
                                            <h5
                                                className="title"
                                                style={{
                                                    paddingTop: '10px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Treatment of Endometriosis
                                            </h5>
                                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="appie-traffic-service item-2 mb-30">
                                            <div className="icon">
                                                {/* <i className="fal fa-check" /> */}
                                                <img src={point} alt="" style={{ width: '35px' }} />
                                            </div>
                                            <h5
                                                className="title"
                                                style={{
                                                    paddingTop: '10px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Adhesiolysis Surgeries
                                            </h5>
                                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="appie-traffic-service item-3">
                                            <div className="icon">
                                                {/* <i className="fal fa-check" /> */}
                                                <img src={point} alt="" style={{ width: '35px' }} />
                                            </div>
                                            <h5
                                                className="title"
                                                style={{
                                                    paddingTop: '10px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Surgeries to Avoid Hysterectomy
                                            </h5>
                                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="appie-traffic-service item-4">
                                            <br />
                                            <div className="icon">
                                                <br />
                                                {/* <i className="fal fa-check" /> */}
                                                <img src={point} alt="" style={{ width: '35px' }} />
                                            </div>
                                            <h5
                                                className="title"
                                                style={{
                                                    paddingTop: '10px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Pelvic Floor Repair
                                            </h5>
                                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" style={{ width: '30px' }} />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" style={{ width: '30px' }} />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" style={{ width: '30px' }} />
                </div>
            </section>
        </>
    );
}

export default SaveUterus;
