import Carousel from 'nuka-carousel/lib/carousel';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import heroThumbOne from '../../assets/images/doctor.jpeg';
import Shanmugavadivu from '../../assets/images/Dr. Rhea.jpg';
import border from '../../assets/images/rhea1.png';
import shapeTwo from '../../assets/images/rhea2.png';
import shapeThree from '../../assets/images/rhea3.png';
import shapeFour from '../../assets/images/rhea4.png';

function HeroHomeOne({ className }) {
    return (
        <>
            <section
                className={`appie-hero-area ${className || ''}`}
                style={{
                    backgroundImage: `url(${border})`,
                    backgroundSize: ' 200px ',
                    backgroundPosition: 'bottom right',
                    backgroundRepeat: ' no-repeat ',
                }}
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <span>About</span>
                                <h1 className="appie-title" style={{ fontSize: '32px' }}>
                                    Dr K Shanmugavadivu Venkatesan
                                </h1>
                                <p>
                                    <i>
                                        MBBS (University of Madras)
                                        <br />
                                        DGO (Annamalai University)
                                        <br />
                                        Diploma in Advanced Gynaecology Endoscopy (CICE, France)
                                        <br />
                                        <br />
                                    </i>
                                </p>
                                <p>
                                    She is a passionate Hystero Laparoscopic surgeon, IVF /
                                    Infertility Specialist, Sonologist, Embryologist, Colposcopist
                                    and a dedicated OBGYNAE with clinical experience of more than 30
                                    years.
                                </p>
                                <br />
                                <p>
                                    A Passionate Gynae Endoscopic Surgeon with special interest in
                                    the Field of Infertility, IVF & Embryology, started her career
                                    as a sonologist focusing on Infertility and then completed her
                                    Postgraduate in Obstetrics & Gynecology. After entering OBG, She
                                    focused on learning programmed labor which shortened the phases
                                    of Normal delivery. Her passion towards endoscopy grew after
                                    attending conferences worldwide and discovered that it was her
                                    ultimate field which satisfied her soul.
                                </p>
                                <br />
                                <p>
                                    Her Ultimate focus in life is to bring the best health care
                                    services to the suffering,needy people, especially to rural
                                    communities, who lack the latest tertiary care services of a
                                    city.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <Carousel>
                                        <img
                                            src={Shanmugavadivu}
                                            alt=""
                                            style={{
                                                boxShadow:
                                                    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
                                            }}
                                        />
                                        <img
                                            src={heroThumbOne}
                                            alt=""
                                            style={{
                                                boxShadow:
                                                    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
                                            }}
                                        />
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" style={{ width: '30px' }} />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" style={{ width: '30px' }} />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" style={{ width: '30px' }} />
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
