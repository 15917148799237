import React from 'react';
import { Link } from 'react-router-dom';
import bcclogo from '../../assets/images/logo-new-white.png';
import IconThree from '../../assets/images/mail.png';
import IconTwo from '../../assets/images/map.png';
import logo from '../../assets/images/Rhea_02.png';
import IconOne from '../../assets/images/telephone-call.png';

function FooterHomeOne() {
    return (
        <>
            <section className="appie-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-about-widget footer-about-widget-2">
                                <div className="logo">
                                    <center>
                                        <a href="#">
                                            <img src={logo} alt="" style={{ width: '200px' }} />
                                        </a>
                                    </center>
                                </div>
                                <p style={{ color: 'white', textAlign: 'center' }}>
                                    Rhea IVF & Women's Hospital is one of the best fertility center
                                    in Tamil Nadu. Our hospital is a state-of-the-art IVF centre
                                    equipped with advanced facilities to ensure that every hopeful
                                    parent realises their dream.
                                </p>
                                <div className="social mt-30">
                                    <ul style={{ textAlign: 'center', marginLeft: '70px' }}>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/rheaivfhospital"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://twitter.com/rheaivfhospital"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/rheaivfhospital"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/rheaivfhospital"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@rheaivfhospital"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="footer-navigation footer-navigation-2">
                                <h4
                                    className="title"
                                    style={{
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                >
                                    Quick Link
                                </h4>
                                <ul
                                    style={{
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <li className="footerli">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="footerli">
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li className="footerli">
                                        <Link to="/Service">Services</Link>
                                    </li>
                                    <li className="footerli">
                                        <a href="#">Doctors</a>
                                    </li>
                                    <li className="footerli">
                                        <Link to="/news">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <hr style={{ backgroundColor: 'white' }} />
                            <section
                                className="appie-service-area"
                                id="service"
                                style={{
                                    backgroundColor: ' #8551a0 ',
                                    backgroundImage: ' none ',
                                }}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="icon">
                                                <center>
                                                    <img
                                                        src={IconOne}
                                                        alt=""
                                                        style={{ width: '80px' }}
                                                    />
                                                </center>
                                                {/* <span>1</span> */}
                                            </div>
                                            <h4 className="appie-title">Call</h4>
                                            <h4
                                                className="text-center"
                                                style={{ textAlign: 'center', color: 'white' }}
                                            >
                                                <b>94451 211170</b>
                                                <br />
                                                <b>04151-290299</b>
                                            </h4>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="icon">
                                                <center>
                                                    <img
                                                        src={IconTwo}
                                                        alt=""
                                                        style={{ width: '80px' }}
                                                    />
                                                </center>
                                                {/* <span>2</span> */}
                                            </div>
                                            <h4 className="appie-title">Addess</h4>
                                            <p style={{ textAlign: 'center', color: 'white' }}>
                                                <b>Rhea IVF and Womens Hospital</b>
                                                <br />
                                                Taj Towers, 204/4A, Durugam Main road, opposite to
                                                Akt Engineering college gate, Kallakurichi, Tamil
                                                Nadu 606202
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="icon">
                                                <center>
                                                    <img
                                                        src={IconThree}
                                                        alt=""
                                                        style={{ width: '80px' }}
                                                    />
                                                </center>
                                                {/* <span>3</span> */}
                                            </div>
                                            <h4 className="appie-title">Mail</h4>
                                            <h6 className="text-center" style={{ color: 'white' }}>
                                                <b>info@rheaivfandwomenshospital.com</b>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                                    footer-copyright
                                    d-flex
                                    align-items-center
                                    justify-content-between
                                    pt-35
                                "
                            >
                                <div className="apps-download-btn">
                                    <p style={{ color: 'white' }}>
                                        Copyright © 2023 Rhea IVF and Womens Hospital. All rights
                                        reserved.
                                    </p>
                                </div>
                                <div className="copyright-text">
                                    <p style={{ color: 'white' }}>
                                        Powewd by
                                        <span>
                                            <img src={bcclogo} alt="" />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
