import $ from 'jquery';
import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Form() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [message, setMessage] = useState('');
    const [result, setResult] = useState('');
    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
            success(data) {
                setResult(data);
                setName('');
                setEmail('');
                setPhone('');
                setLocation('');
                setMessage('');
            },
        });
    };
    return (
        <>
            <div className="contact-form" style={{ backgroundColor: '#efefef' }}>
                <h1 className="appie-title text-center" style={{ fontSize: '25px' }}>
                    Book an Appointment
                    <br />
                    <br />
                </h1>
                <form
                    className="row"
                    action="https://rhea.bcchealthcarebranding.com/server/mail.php"
                    method="post"
                    onSubmit={(event) => handleSumbit(event)}
                >
                    <div className="col-md-6">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Name"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <input
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Phone Number"
                            id="phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Location"
                            id="phone"
                            name="phone"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </div>
                    <div className="col-md-12">
                        <textarea
                            name="message"
                            placeholder="How can we help?"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="col-md-12">
                        <center>
                            <button type="submit" className="site-btn site-btn__2 mt-10 main-btn">
                                Send
                            </button>
                        </center>
                    </div>
                    <h4 className="title">{result}</h4>
                </form>
            </div>
        </>
    );
}

export default Form;
